import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import concurso_modal from '../../assets/images/ModalInicio/Concurso-Modal.png'

const ModalConcurso = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Apertura del Concurso de Posgrado de Medicina 2024</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          <img src={concurso_modal} alt='Apertura del Concurso de Posgrado de Medicina 2024' className='modal-banner' />

          <p className='heading-p'>La División de Estudios para Graduados de la Facultad de Medicina de la Universidad del Zulia, da inicio al Concurso 2024.</p>

          <div className='info-group'>
            <h3 className='modal-subtitle continuous'>Publicación del Baremo e Instructivo 2024</h3>
            <p className='info-p'>Lunes 06 de mayo de 2024.</p>
          </div>

          <div className='info-group'>
            <h3 className='modal-subtitle'>Recepción de Credenciales</h3>

            <p className='info-p'>Desde lunes 06 de mayo hasta el 30 de septiembre de 2024. </p>
            <p className='info-p' dangerouslySetInnerHTML={{ __html: '<u>Nota</u>: Tomar previsiones del periodo vacacional que inicia en el mes de agosto 2024. ' }} />

            <h4 className='highlight-title'>NO HABRÁ PRÓRROGA</h4>
          </div>

          <div className='info-group'>
            <h3 className='modal-subtitle'>Examen de Conocimientos</h3>
            <p className='info-p'>Sábado 12 de octubre de 2024. </p>
            <p className='info-p'>Fecha única a nivel nacional. </p>
            <p className='info-p'>Hora: 7:00 AM</p>
            <p className='info-p' dangerouslySetInnerHTML={{ __html: '<b>Lugar</b>: Edificio Ciencia y Salud de la Facultad de Medicina de LUZ. ' }} />
          </div>

          <p className='highlight-title'>VER VIDEO INSTRUCTIVO PARA PARTICIPAR EN EL CONCURSO 2024.</p>

          <Link to={'/concurso/instructivo'} className='btn btn-primary link-button rounded-pill'>Ver vídeo instructivo</Link>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalConcurso }