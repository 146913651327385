import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ModalActCupos = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Actualización Oferta de Cupos Concurso 2024</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container cards-center text-center'>
          {/* <img src={img_modal} alt='Apertura del Concurso de Posgrado de Medicina 2024' className='modal-banner sm card' /> */}

          <p className='heading-title fw-semibold'>Programas</p>

          <div className='info-group card'>
            <p className='modal-subtitle'>Sede Hospital Coromoto</p>
            <p>Cirugía General</p>
            <p>Ortopedia y Traumatología</p>
            <p className='mb-1'>Cardiología</p>
          </div>

          <div className='info-group card'>
            <p className='modal-subtitle'>Sede IDEO</p>
            <p className='mb-1'>Retina, Segmento Anterior y Glaucoma</p>
          </div>

          <div className='info-group card'>
            <p className='modal-subtitle'>Sede Central</p>
            <p className='mb-1'>Gastroenterología</p>
          </div>

          <Link to={'/concurso/oferta-de-cupos'} className='btn btn-primary link-button px-3 mb-3'>
            Ver Oferta de Cupos
          </Link>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalActCupos }