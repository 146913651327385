import React from 'react'
import { Button, Modal } from 'react-bootstrap';
// import img_modal from '../../assets/images/ModalInicio/BannerProgramasPEC-Modal.png'

const links = {
  formSaludMental: 'https://forms.gle/ZahGTPVyAseeR5PX6',
  formPruebaIngles: 'https://forms.gle/sinMK8hwGqSgTES89',
  formCursoSaludPublica: 'https://forms.gle/D7XUHB4bCk6FBr448'
}

const ModalPec = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Cronograma de Actividades</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          {/* <img src={img_modal} alt='Apertura del Concurso de Posgrado de Medicina 2024' className='modal-banner' /> */}

          <h3 className='heading-title mb-4'>Programa de Educación Continua (PEC)</h3>

          <div className='cards-group'>
            <div className='info-group card'>
              <h3 className='modal-subtitle continuous heading'>Prueba de Salud Mental (Reprogramada)</h3>
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Fecha</b>: Miércoles 26 de junio de 2024 '}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 8:30 AM '}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud 2do. Piso. Salón de Usos Múltiples '}} />
              <p className='info-p spaced-sm' dangerouslySetInnerHTML={{__html: '<b>Coordinador</b>: Dr. Cesar Marin'}} />

              <a href={links.formSaludMental} target='blank' className='btn btn-primary link-button rounded-pill center'>
                Formulario de Registro
              </a>
            </div>

            <div className='info-group card'>
              <h3 className='modal-subtitle continuous heading'>Examen de Suficiencia de Inglés (Reprogramado)</h3>
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Fecha</b>: Jueves 27 de junio de 2024 '}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 2:00 PM '}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud. Salón de Usos Múltiples '}} />
              <p className='info-p spaced-md' dangerouslySetInnerHTML={{__html: '<b>Jefe de la Cátedra de Inglés</b>: Prof. Virginia Novoa'}} />

              <h4 className='highlight-title'>PARA REALIZAR LA PRUEBA NECESITARÁS: </h4>
              <ul className='list-start centered'>
                <li>Hoja de examen</li>
                <li>Lápiz de grafito</li>
                <li>Diccionario de papel</li>
              </ul>

              <a href={links.formPruebaIngles} target='blank' className='btn btn-primary link-button rounded-pill center'>
                Formulario de Registro
              </a>
            </div>

            <div className='info-group card'>
              <h3 className='modal-subtitle continuous heading'>Curso Medio de Salud Pública</h3>
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Fecha</b>: 17 de julio de 2024. '}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 8:30 AM '}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud 2do. Piso. Salón de Usos Múltiples '}} />
              <p className='info-p spaced-md' dangerouslySetInnerHTML={{__html: '<b>Coordinadora</b>: Dra. Noris Acosta'}} />

              <a href={links.formCursoSaludPublica} target='blank' className='btn btn-primary link-button rounded-pill center'>
                Formulario de Registro
              </a>
            </div>
          </div>

        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalPec }